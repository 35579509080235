<template>
  <asset-condition-form
    ref="asset-condition-form"
    :is-create-form="true"
  />
</template>
<script>
import AssetConditionForm from '@/views/settings/inventory/asset-condition/components/AssetConditionForm.vue'

export default {
  name: 'AssetConditionCreate',
  components: {
    AssetConditionForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['asset-condition-form']) {
      this.$refs['asset-condition-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = {
      active: false,
      name: '',
    }
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_ASSET_CONDITION_FORM_CLONE`, initialData)
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'asset-condition-taxonomies'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
